import styled from 'styled-components'

const BrandLogo = require('../../assets/images/svgs/logo.svg')
const SimpleLogo = require('../../assets/images/svgs/simpleLogo.svg')

export const Logo = styled.div`
  background: url(${props => props.useFavicon ? SimpleLogo : BrandLogo });
  position: ${props => props.position ? props.position : 'absolute' };
  width: ${props => props.width ? props.width+'px' : '180px' };
  height: ${props => props.height ? props.height+'px' : '109px' };
  background-repeat: no-repeat;
`

